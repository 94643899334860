import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';
import Button from './Button';

const StyledOverlayBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  .overlay-box-inner {
    position: relative;
    padding: 2rem;
    background: ${colors.white};
    border-radius: 10px;
    min-width: ${(props) => (props.pdfWidth ? 'calc(297mm + 4rem)' : '50vw')};
    max-width: calc(100vw - 3rem);
  }
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
  .overflow-box-content {
    overflow-y: scroll;
    width: 100%;
    max-height: calc(100vh - 12rem);
    button {
      margin-right: 0.5rem;
      margin-top: 1rem;
    }
  }
  .customer-card-header-right-content button {
    margin-left: 0.5rem;
  }
  .overlay-box-close-warning-outer {
    transition: 100ms ease-in-out;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    .overlay-box-close-warning-inner {
      background: ${colors.white};
      padding: 2rem;
      min-width: 30vw;
      border-radius: 10px;
      button {
        margin-top: 1rem;
        margin-right: 0.5rem;
      }
    }
    &.active {
      opacity: 1;
      pointer-events: initial;
    }
  }
  @media (max-width: 1000px) {
    padding: 1rem;
    max-width: 100vw;
    .overlay-box-inner {
      padding: 1rem;
      width: 100%;
    }
  }
`;

const OverlayBox = (props) => {
  const [closePending, setClosePending] = useState(false);
  return (
    <StyledOverlayBox
      pdfWidth={props.pdfWidth}
      className={props.active ? 'active ' + props.className : props.className}
    >
      <div className="overlay-box-inner">
        <div className="customer-card-header">
          {props.icon ? <img src={props.icon} alt="" /> : null}
          {props.header ? <h3>{props.header}</h3> : null}

          <div className="customer-card-header-right-content">
            {props.headerContent && props.headerContent}
            <Button
              noBg
              onClick={() => {
                if (props.closeWarning) {
                  setClosePending(true);
                } else {
                  props.close();
                }
              }}
            >
              Stäng
            </Button>
          </div>
        </div>
        <div className="overflow-box-content">{props.children}</div>
      </div>
      <div
        className={`overlay-box-close-warning-outer ${
          closePending && props.active ? 'active' : ''
        }`}
      >
        <div className="overlay-box-close-warning-inner">
          <div className="customer-card-header">
            <h3>Är du säker?</h3>
          </div>
          <p>Vill du stänga utan att spara dina ändringar?</p>
          <Button
            small
            look="danger"
            onClick={() => {
              props.close();
              setClosePending(false);
            }}
          >
            Stäng
          </Button>
          <Button small onClick={() => setClosePending(false)}>
            Avbryt
          </Button>
        </div>
      </div>
    </StyledOverlayBox>
  );
};

export default OverlayBox;
