import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import '../utils/index.css';
import { colors } from '../utils/siteVars';
import BtnNoStyle from './BtnNoStyle';
import Button from './Button';
import Hamburger from './Hamburger';
import LinkPreventWrapper from './LinkPreventWrapper';

const StyledNavbar = styled.nav`
  .nav-inner {
    background: ${colors.lightBlue};
    color: ${colors.white};
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .nav-left {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      align-items: center;
      a.logo-circle {
        background: ${colors.white};
        display: block;
        width: 55px;
        height: 55px;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          &.error {
            object-fit: contain;
          }
        }
      }
      .nav-name {
        margin-left: 1rem;
        flex-grow: 1;
        p {
          margin: 0;
          &.nav-role {
            font-size: 0.8rem;
            opacity: 0.6;
          }
        }
      }
      button.profile-menu-button {
        img {
          margin-left: 1rem;
          margin-bottom: 1rem;
        }
      }
      position: relative;
      .profile-menu {
        position: absolute;
        top: 5rem;
        right: 1rem;
        opacity: 0;
        pointer-events: none;
        transition: 200ms ease-in-out;
        background: ${colors.white};
        padding: 1rem;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        a {
          display: block;
          width: 100%;
        }
        button {
          width: 100%;
          white-space: nowrap;
        }
        z-index: 2;
        box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.1);
        &.active {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
    .nav-center {
      display: none;
    }
    .nav-right {
      margin-top: calc(55px + 0.5rem);
      width: 100%;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    a {
      position: relative;
      padding: 1rem 1.5rem;
      li {
        font-size: 1.1rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
          margin-right: 20px;
          display: inline-block;
        }
      }
      transition: 80ms ease-in-out;
      &.active,
      &:hover {
        color: ${colors.lightBlue};
        background: ${colors.white};
      }
    }
  }
  .hamburger-container,
  .mobile-dropdown {
    display: none;
  }

  @media (max-width: 1350px) {
    .nav-inner {
      width: 120px;
      .nav-left {
        padding: 1rem;
        a.logo-circle {
          width: 50px;
          height: 50px;
        }
        .profile-menu {
          right: -3rem;
        }
        .nav-name {
          display: none;
        }
      }
    }
    ul {
      a {
        li {
          flex-direction: column;
          img {
            margin-right: 0;
            margin-bottom: 1rem;
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .nav-inner {
      top: 0;
      left: 0;
      right: 0;
      bottom: initial;
      width: 100%;
      height: initial;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem;
      align-items: center;
      .hamburger-container {
        display: block;
      }
      .nav-right {
        display: none;
      }
      .nav-left {
        padding: 0;
        width: initial;
        .profile-menu {
          top: 70px;
          left: 0;
          right: initial;
        }

        a.logo-circle {
          width: 40px;
          height: 40px;
        }
      }
    }
    .mobile-dropdown {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
      width: 100%;
      display: flex;
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      z-index: 9;
      background: ${colors.white};
      max-height: 0;
      pointer-events: none;
      transition: 400ms ease-in-out;
      overflow: hidden;
      padding: 0;
      ul {
        width: 100%;
        a {
          li {
            padding: 0 3rem;
          }
          &.active {
            background: ${colors.lighterBlue};
            color: ${colors.white};
          }
        }
      }
      &.open {
        padding: 40px 0;
        overflow: scroll;
        max-height: 100vh;
        pointer-events: initial;
      }
    }
  }
`;

const baseNavLinks = [
  {
    text: 'Notiser',
    link: '/notifications',
    icon: 'notifications',
  },
  {
    text: 'Översikt',
    link: '/home',
    icon: 'overview',
  },
  {
    text: 'Kunder',
    link: '/customers',
    icon: 'customers',
  },
  {
    text: 'Filer',
    link: '/media',
    icon: 'media',
  },
];

const Navbar = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  const [navLinks, setNavLinks] = useState(baseNavLinks);

  useEffect(() => {
    if (state.user.role) {
      if (state.user.role === 'Admin') {
        setNavLinks([
          ...baseNavLinks,
          {
            text: 'Admin',
            link: '/admin',
            icon: 'admin',
          },
        ]);
      }
    }
  }, [state.user]);

  const currentLocation =
    typeof window !== `undefined` ? window.location : { pathname: '' };

  const ul = (
    <ul>
      {navLinks.map((link) => (
        <LinkPreventWrapper
          onClick={() => setMobileMenuOpen(false)}
          to={link.link}
          key={link.link}
          data-test={`nav-item-${link.text}`}
          className={
            currentLocation.pathname.split('/')[1] === link.link.split('/')[1]
              ? 'active'
              : ''
          }
        >
          <li>
            {currentLocation.pathname.split('/')[1] ===
            link.link.split('/')[1] ? (
              <img src={`/icons/${link.icon}.png`} alt="" />
            ) : (
              <img src={`/icons/${link.icon}_light.png`} alt="" />
            )}
            <span>{link.text}</span>
          </li>
        </LinkPreventWrapper>
      ))}
    </ul>
  );
  return (
    <StyledNavbar data-test="navigation">
      <div className="nav-inner">
        <div className="nav-left">
          <LinkPreventWrapper
            to="/"
            className="logo-circle"
            onClick={(e) => {
              if (state.preventNavigation) {
                e.preventDefault();
              }
              setMobileMenuOpen(false);
            }}
          >
            <img src="/images/logo.png" alt="Logo" />
          </LinkPreventWrapper>
          <div className="nav-name">
            <p>{state.user.firstName}</p>
            <p className="nav-role">{state.user.role}</p>
          </div>
          <BtnNoStyle
            data-test="profile-menu-button"
            className="profile-menu-button"
            onClick={() => setProfileMenuOpen(!profileMenuOpen)}
          >
            <img src="/icons/min-arrow-down.png" alt="" />
          </BtnNoStyle>
          <div className={`profile-menu ${profileMenuOpen ? 'active' : ''}`}>
            <LinkPreventWrapper
              to="/my-account"
              onClick={() => setMobileMenuOpen(false)}
            >
              <Button small>Mitt konto</Button>
            </LinkPreventWrapper>
            <Button
              look="danger"
              small
              data-test="logout-button"
              className="logout-button"
              onClick={logout}
            >
              Logga ut
            </Button>
          </div>
        </div>
        <BtnNoStyle
          className="hamburger-container"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <Hamburger color={colors.white} active={mobileMenuOpen} />
        </BtnNoStyle>
        <div className="nav-right">{ul}</div>
      </div>
      <div className={`mobile-dropdown ${mobileMenuOpen ? 'open' : ''}`}>
        {ul}
      </div>
    </StyledNavbar>
  );
};

export default Navbar;
