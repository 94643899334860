import { Link, navigate } from 'gatsby';
import React, { useContext } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';

const LinkPreventWrapper = (props) => {
  const { preventNavigation } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  return (
    <Link
      {...props}
      onClick={(e) => {
        if (preventNavigation) {
          e.preventDefault();
          dispatch({
            type: 'SET_PREVENT_NAVIGATE_POPUP',
            value: { active: true, destination: props.to },
          });
        } else {
          navigate(props.to);
        }
      }}
    />
  );
};

export default LinkPreventWrapper;
